import { useWeb3Modal } from "@web3modal/react";
import { useState } from "react";
import { useAccount, useDisconnect } from "wagmi";

export default function CustomButton() {
  const [loading, setLoading] = useState(false);
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const label = isConnected ? address : "Connect Wallet";

  async function onOpen() {
    setLoading(true);
    await open();
    setLoading(false);
  }

  function onClick() {
    if (isConnected) {
      disconnect();
    } else {
      onOpen();
    }
  }

  return (
    <button className="u-border-2 u-border-grey-5 u-btn u-btn-round u-button-style u-grey-80 u-hover-palette-1-light-1 u-radius-50 u-btn-2 u-sticky u-sticky-687d" onClick={onClick} disabled={loading}>
      {loading ? "Loading..." : label}
    </button>
  );
}