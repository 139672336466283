import Miners from './pages/miners';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { skaleNebulaMainnet, skaleNebulaTestnet } from 'wagmi/chains'


const chains = [skaleNebulaTestnet]
const projectId = '465d8252c9116a9610c8acab0e204de1'

const { provider } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider
})
const ethereumClient = new EthereumClient(wagmiClient, chains)

function App() {
  return (
    <>
    <WagmiConfig client={wagmiClient}>
     <Miners />
     </WagmiConfig>
     <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
