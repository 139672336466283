import React from "react";
import Web3 from "web3";
import { vvContractAddress, network, prospectAddress} from "./config";
import vvAbi from "./VoxelVerseABI.json";
import prospectABI from "./ProspectABI.json";
import CustomButton from "./CustomButton";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshNeeded: false,
      miners: [],
      generators: [],
      pendingRewards: new Web3.utils.BN(0),
      generatorsBoost: 0,
      altMinerCost: 0,
      seMinerCost: 0,
      kasMinerCost: 0,
      regMinerCost: 0,
      btcMinerBoostRate: 0,
      sklMinerBoostRate: 0,
      kasMinerBoostRate: 0,
      altMinerBoostRate: 0,
      preSaleMiner: 0,
      selectedCap: 50, // Default selected cap value (can be changed)
      availableCaps: [50, 100, 150], // List of available cap values
      selectedWatts: 1, // Default select watts value (can be changed)
      newName:"", // Change miner name
      bitcoinStats:{
        minersHashing: null,
        totalHashrate: null,
        totalPowerConsumption: null,
        totalRewardsPaid: null,
      },
      skaleStats:{
        minersHashing: null,
        totalHashrate: null,
        blockReward: null,
        totalRewardsPaid: null,
      },
      kaspaStats: {
        minersHashing: null,
        totalHashrate: null,
        totalPowerConsumption: null,
        totalRewardsPaid: null,
      },
      altStats:{
        minersHashing: null,
        totalHashrate: null,
        blockReward: null,
      },
    };
  }
    
  async componentDidMount() {
    const userWallet = await this.fetchUserWallet(); // Fetch token IDs held by the user
    await this.fetchStats();
    await this.fetchMinerDataForTokens(userWallet); // Fetch corresponding miner data
    await this.fetchGeneratorDataForTokens(userWallet); // Fetch corresponding generator data
    await this.fetchBoostGeneratorCost();
    this.fetchMinerCost('se'); // Fetch the cost for se miner
    this.fetchMinerCost('reg'); // Fetch the cost for reg miner
    this.fetchMinerCost('kas'); // Fetch the cost for kas miner
    this.fetchMinerCost('alt'); // Fetch the cost for alt miner
    this.fetchMinerBoostRate('btc'); // Fetch the cost for se miner
    this.fetchMinerBoostRate('skl'); // Fetch the cost for reg miner
    this.fetchMinerBoostRate('kas'); // Fetch the cost for kas miner
    this.fetchMinerBoostRate('alt'); // Fetch the cost for alt miner
    await this.fetchPendingRewards();
    await this.fetchTotalPresale();


    // Set up an interval to fetch pending rewards every minute
    this.pendingRewardsInterval = setInterval(() => {
      this.fetchPendingRewards();
    }, 20000); // 20000 milliseconds = 20 seconds

    this.statsInterval = setInterval(() => {
      this.fetchStats();
    }, 20000); // 20000 milliseconds = 20 seconds

    this.minerDataInterval = setInterval(() => {
      this.fetchMinerDataForTokens(userWallet);
    }, 120000); // 120000 milliseconds = 120 seconds

    this.generatorDataInterval = setInterval(() => {
      this.fetchGeneratorDataForTokens(userWallet);
    }, 240000); // 240000 milliseconds = 240 seconds
    this.userWalletInterval = setInterval(() => {
      this.fetchUserWallet();
    }, 60000); // 60000 milliseconds = 1 minute
     
  }

  async approveTokenSpending(prospectAddress, spenderAddress, amountInWei) {
    const web3 = new Web3(window.ethereum);
    try {
      const tokenContract = new web3.eth.Contract(prospectABI, prospectAddress); // Use the actual ERC20 ABI
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const selectedAccount = accounts[0];
  
      // Convert the amount to the token's decimals (if necessary)
      const tokenDecimals = await tokenContract.methods.decimals().call();
      const approvedAmount = web3.utils.toWei(amountInWei.toString(), 'ether'); // Adjust 'ether' based on token's decimals
  
      // Approve the spender (VoxelVerse Miners contract) to spend the specified amount
      await tokenContract.methods.approve(spenderAddress, approvedAmount).send({ from: selectedAccount });
  
      console.log(`Approved ${amountInWei} PROSPECT spending for ${spenderAddress}`);
    } catch (error) {
      console.error(error);
    }
  }
  
  

  async fetchStats() {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const selectedAccount = accounts[0];
  
    try {
      // Fetch Bitcoin stats
      const bitcoinStats = await contract.methods.bitcoin().call();
      console.log('Bitcoin Stats:', bitcoinStats);
  
      // Fetch Skale stats
      const skaleStats = await contract.methods.skale().call();
      console.log('Skale Stats:', skaleStats);
  
      // Fetch Kaspa stats
      const kaspaStats = await contract.methods.kaspa().call();
      console.log('Kaspa Stats:', kaspaStats);
  
      // Fetch Alt stats
      const altStats = await contract.methods.alt().call();
      console.log('Alt Stats:', altStats);
  
      this.setState({
        bitcoinStats: {
          minersHashing: bitcoinStats[0],
          totalHashrate: bitcoinStats[1],
          totalPowerConsumption: bitcoinStats[2],
          totalRewardsPaid: bitcoinStats[3],
        },
        skaleStats: {
          minersHashing: skaleStats[0],
          totalHashrate: skaleStats[1],
          blockReward: skaleStats[2],
          totalRewardsPaid: skaleStats[3],
        },
        kaspaStats: {
          minersHashing: kaspaStats[0],
          totalHashrate: kaspaStats[1],
          totalPowerConsumption: kaspaStats[2],
          totalRewardsPaid: kaspaStats[3],
        },
        altStats: {
          minersHashing: altStats[0],
          totalHashrate: altStats[1],
          blockReward: altStats[2],
        },
      });
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  }
  
  async fetchTotalPresale() {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const selectedAccount = accounts[0];
  
    try {
      // Fetch total kaspa miners minted
      const kaspaMinersMinted = await contract.methods.preSaleMiner().call();
      console.log('Kaspa Miners:', kaspaMinersMinted);
  
      this.setState({preSaleMiner: kaspaMinersMinted});
    } catch (error) {
      console.error('Error fetching Kaspa miners minted:', error);
    }
  }
  
  

  async fetchUserWallet() {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
  
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const selectedAccount = accounts[0];
  
    const userWallet = await contract.methods.walletOfOwner(selectedAccount).call();
    return userWallet;
  }

  async fetchMinerDataForTokens(tokens) {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
  
    const minersData = [];
    for (const tokenId of tokens) {
      // Check if tokenId is within the range of 1 to 16199
      if (tokenId >= 1 && tokenId <= 16199) {
        try {
          const minerData = await contract.methods.miners(tokenId).call();
  
          // Access struct members by their names
          const token = minerData.token;
          const name = minerData.name;
          const hashrate = minerData.hashrate;
          const hashMeasured = minerData.hashMeasured;
          const powerConsumption = minerData.powerConsumption;
          const rewardPerBlock = minerData.rewardPerBlock;
  
          console.log(`Token ID: ${tokenId}, Miner Name: ${name}, Hashrate: ${hashrate}, Power Consumption: ${powerConsumption}`);
  
          minersData.push(minerData);
        } catch (error) {
          console.error(`Error fetching data for Token ID: ${tokenId}`, error);
        }
      }
    }
  
    // Update your state with minersData
    this.setState({ miners: minersData }, () => {
      console.log('Updated State:', this.state.miners); // Check the updated state
    });
  }

  async fetchGeneratorDataForTokens(tokens) {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
  
    const generatorsData = [];
    for (const tokenId of tokens) {
      // Check if tokenId is within the range of 16200 or higher
      if (tokenId >= 16200 && tokenId <= 32200) {
        try {
          const generatorData = await contract.methods.generators(tokenId).call();
  
          // Access generator data fields
          const currentPowerUsed = generatorData.currentPowerUsed;
          const generatorCap = generatorData.generatorCap;
  
          console.log(`Token ID: ${tokenId}, Current Power Used: ${currentPowerUsed}, Generator Cap: ${generatorCap}`);
  
          generatorsData.push(generatorData);
        } catch (error) {
          console.error(`Error fetching data for Token ID: ${tokenId}`, error);
        }
      }
    }
    this.setState({ generators: generatorsData });
}

async fetchBoostGeneratorCost() {
  const web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(vvAbi, vvContractAddress);

  try {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const selectedAccount = accounts[0];

    const generatorRate = await contract.methods.generatorBoostRate().call();

    console.log('Boost Rate:', generatorRate);

    // Update your state with the fetched generator rate
    this.setState({ generatorsBoost: generatorRate });
  } catch (error) {
    console.error('Error fetching generator boost rate:', error);
  }
}

async fetchMinerCost(minerType) {
  const web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(vvAbi, vvContractAddress);

  try {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const selectedAccount = accounts[0];

    let minerRate;

    if (minerType === 'se') {
      minerRate = await contract.methods.sePrice().call();
    } else if (minerType === 'reg') {
      minerRate = await contract.methods.regPrice().call();
    } else if (minerType === 'kas') {
      minerRate = await contract.methods.kasPrice().call();
    } else if (minerType === 'alt') {
      minerRate = await contract.methods.altPrice().call();
    } else {
      throw new Error('Invalid minerType');
    }

    console.log(`${minerType} Miner Rate:`, minerRate);

    // Update your state with the fetched miner rate
    this.setState({ [`${minerType}MinerCost`]: minerRate });
  } catch (error) {
    console.error(`Error fetching ${minerType} miner rate:`, error);
  }
}

async fetchMinerBoostRate(minerType) {
  const web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(vvAbi, vvContractAddress);

  try {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const selectedAccount = accounts[0];

    let minerBoostRate;

    if (minerType === 'btc') {
      minerBoostRate = await contract.methods.btcBoostRate().call();
    } else if (minerType === 'skl') {
      minerBoostRate = await contract.methods.sklBoostRate().call();
    } else if (minerType === 'kas') {
      minerBoostRate = await contract.methods.kasBoostRate().call();
    } else if (minerType === 'alt') {
      minerBoostRate = await contract.methods.altBoostRate().call();
    } else {
      throw new Error('Invalid minerType');
    }

    console.log(`${minerType} Miner Boost Rate:`, minerBoostRate);

    // Update your state with the fetched miner rate
    this.setState({ [`${minerType}MinerBoostRate`]: minerBoostRate });
  } catch (error) {
    console.error(`Error fetching ${minerType} miner rate:`, error);
  }
}



async fetchPendingRewards() {
  try {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(vvAbi, vvContractAddress);

    // Fetch pending rewards for each miner
    const userWallet = await this.fetchUserWallet();
    const pendingRewards = {};

    for (const tokenId of userWallet) {
      const pendingReward = await contract.methods.getPendingRewards(tokenId).call();
      pendingRewards[tokenId] = new web3.utils.BN(pendingReward);
    }

    // Update your state with the pending rewards object
    this.setState({ pendingRewards });
  } catch (error) {
    console.error('Error fetching pending rewards:', error);
  }
}



  

  handleCapChange = (event) => {
    const selectedCap = event.target.value;
    // Calculate the priceInWei based on the selectedCap
    let priceInWei;
    if (selectedCap === "50") {
      priceInWei = "40000000000000000000"; // 40 ether in wei for cap 50
    } else if (selectedCap === "100") {
      priceInWei = "60000000000000000000"; // 60 ether in wei for cap 100
    } else if (selectedCap === "150") {
      priceInWei = "90000000000000000000"; // 90 ether in wei for cap 150
    }
    
    // Update the state with the selectedCap and priceInWei
    this.setState({ selectedCap, priceInWei });
  };

  handleWattChange = (event) => {
    const selectedWatts = parseInt(event.target.value, 10);
    const { generatorsBoost } = this.state;
    const priceInWei = selectedWatts * generatorsBoost; // Calculate the price in Wei based on the selected watts
  
    this.setState({ selectedWatts, priceInWei });
  };
    
  
    
  mintWindmill = async () => {
    const web3 = new Web3(window.ethereum);
    
    try {
      const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const selectedAccount = accounts[0];
      const { selectedCap, priceInWei } = this.state;
      const amountToApprove = 90;

      await this.approveTokenSpending(prospectAddress, vvContractAddress, amountToApprove);

      await contract.methods.mintWindmill(selectedCap).send({ from: selectedAccount });

      this.setState({ refreshNeeded: true });

    } catch (error) {
      console.error(error);
    }
  };

  mintBTCMiner = async () => {
    const web3 = new Web3(window.ethereum);
    try {
      // Initialize the contract instance
      const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
  
      // Request user accounts
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const selectedAccount = accounts[0];
      const amountToApprove = 125; 

      await this.approveTokenSpending(prospectAddress, vvContractAddress, amountToApprove);
  
      await contract.methods.mintMiner(1, 0).send({ from: selectedAccount });
  
      // Transaction successful, btc miner minted
    } catch (error) {
      console.error('Error minting miner:', error);
    }
  };

  mintSKLMiner = async () => {
    const web3 = new Web3(window.ethereum);
    try {
      // Initialize the contract instance
      const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
  
      // Request user accounts
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const selectedAccount = accounts[0];
      const amountToApprove = 25; 

      await this.approveTokenSpending(prospectAddress, vvContractAddress, amountToApprove);
  
      await contract.methods.mintMiner(2, 0).send({ from: selectedAccount });
  
      // Transaction successful, skl miner minted
    } catch (error) {
      console.error('Error minting miner:', error);
    }
  };

  mintKASMiner = async () => {
    const web3 = new Web3(window.ethereum);
    try {
      // Initialize the contract instance
      const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
  
      // Request user accounts
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const selectedAccount = accounts[0];
      const amountToApprove = 250; 

      await this.approveTokenSpending(prospectAddress, vvContractAddress, amountToApprove);
  
      await contract.methods.mintMiner(3, 0).send({ from: selectedAccount });
  
      // Transaction successful, kas miner minted
    } catch (error) {
      console.error('Error minting miner:', error);
    }
  };

  mintALTMiner = async () => {
    const web3 = new Web3(window.ethereum);
    try {
      // Initialize the contract instance
      const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
  
      // Request user accounts
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const selectedAccount = accounts[0];
      const amountToApprove = 25; 

      await this.approveTokenSpending(prospectAddress, vvContractAddress, amountToApprove);
  
      await contract.methods.mintMiner(4, 0).send({ from: selectedAccount });
  
      // Transaction successful, alt miner minted
    } catch (error) {
      console.error('Error minting miner:', error);
    }
  };

  boostGeneratorWatts = async (tokenIdToBoost) => {
    const web3 = new Web3(window.ethereum);
    try {
      const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const selectedAccount = accounts[0];
      const { selectedWatts } = this.state;
      const amountToApprove = 100; 

      await this.approveTokenSpending(prospectAddress, vvContractAddress, amountToApprove);
  
      await contract.methods.boostGenerator(tokenIdToBoost, selectedWatts, 0).send({ from: selectedAccount });
    } catch (error) {
      console.error(error);
    }
  };
  

  claimRewards = async (tokenIdToClaim) => {
    const web3 = new Web3(window.ethereum);
    try {
      const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const selectedAccount = accounts[0];

      await contract.methods.claimRewards(tokenIdToClaim).send({ from: selectedAccount });
    } catch (error) {
      console.error(error);
    }
  };

  boostMinerHashrate = async (tokenIdToBoost) => {
    const web3 = new Web3(window.ethereum);
    try {
      // Initialize the contract instance
      const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
  
      // Request user accounts
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const selectedAccount = accounts[0];
      const amountToApprove = 30; 

      await this.approveTokenSpending(prospectAddress, vvContractAddress, amountToApprove);
  
      // Send the transaction to boost miner hashrate with the determined price
      await contract.methods.boostMinerHash(tokenIdToBoost, 0).send({ from: selectedAccount });
  
      // Transaction successful, hashrate boosted
      console.log(`Miner with tokenId ${tokenIdToBoost} hashrate boosted successfully.`);
    } catch (error) {
      console.error('Error boosting miner hashrate:', error);
    }
  };  
  

  updateMinerName = async (tokenIdToUpdate) => {
    const web3 = new Web3(window.ethereum);
    try {
      const contract = new web3.eth.Contract(vvAbi, vvContractAddress);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const selectedAccount = accounts[0];
      const { newName } = this.state;
  
      await contract.methods.updateMinerName(tokenIdToUpdate, newName).send({ from: selectedAccount });
  
      // Optionally, you can fetch updated data and refresh the UI here
      // Example: await this.fetchMinerDataForTokens(userWallet);
  
    } catch (error) {
      console.error(error);
    }
  };
  
  

    render() {
      return (
          <body className="u-body u-xl-mode" data-lang="en"><header className=" u-border-no-bottom u-border-no-left u-border-no-right u-border-no-top u-clearfix u-header u-section-row-container" id="sec-f307" style={{}}><div className="u-section-rows">
          <div className="u-black u-border-2 u-border-white u-section-row u-sticky u-sticky-687d u-section-row-1" id="sec-bdc5">
            <div className="u-clearfix u-sheet u-sheet-1">
              <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                <div className="u-layout">
                  <div className="u-layout-row">
                    <div className="u-container-style u-layout-cell u-size-30 u-layout-cell-1">
                      <div className="u-container-layout u-container-layout-1">
                        <a href="https://www.voxelverse.ca" className="u-align-center u-border-2 u-border-grey-5 u-btn u-btn-round u-button-style u-custom-color-5 u-hidden-sm u-hidden-xs u-hover-palette-1-light-1 u-radius-50 u-btn-1" target="_blank">enter beta gameplay</a>
                      </div>
                    </div>
                    <div className="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
                      <div className="u-container-layout u-valign-top-xs u-container-layout-2">
                      <CustomButton />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            
            
            
            
          </div>
          <div className="u-image u-section-row u-shading u-image-1" id="sec-32ba" data-image-width="1593" data-image-height="836">
            <div className="u-clearfix u-sheet u-sheet-2">
              <h4 className="u-custom-font u-text u-text-default u-text-palette-1-base u-text-1">voxel</h4>
              <h4 className="u-custom-font u-text u-text-default u-text-palette-4-base u-text-2">verse</h4>
              <img className="u-image u-image-contain u-image-default u-image-2" src="/assets/images/4sourdough.png" alt="" data-image-width="3840" data-image-height="2160"/>
              <h3 className="u-align-center u-custom-font u-text u-text-3">3E interactive </h3>
              <h3 className="u-align-center u-custom-font u-text u-text-default u-text-4">nft'd crypto miners</h3>
            </div>
            
            
            
            
            
          </div>
        </div></header>
      <section className="u-black u-clearfix u-section-1" id="sec-521a">
        <div className="u-clearfix u-sheet u-sheet-1">
          <div className="u-clearfix u-gutter-10 u-layout-wrap u-layout-wrap-1">
            <div className="u-layout">
              <div className="u-layout-row">
                <div className="u-container-style u-image u-layout-cell u-radius-14 u-shading u-size-30 u-image-1" data-image-width="1493" data-image-height="1024">
                  <div className="u-border-8 u-border-grey-80 u-container-layout u-container-layout-1">
                    <img className="u-image u-image-contain u-image-default u-preserve-proportions u-image-2" src="/assets/images/kaspa.png" alt="" data-image-width="200" data-image-height="200"/>
                    <div className="fr-view u-align-center u-clearfix u-rich-text u-text u-text-1">
                      <h3>
                        <span style={{ fontSize: '1.5rem' }}>
                          <span className="u-text-palette-4-light-1">
                            <span className="u-custom-font" style={{ fontFamily: 'Koulen' }}>
                              
                                <span style={{ fontSize: '1.5rem' }}>KASPA PROSPECTOR PREORDER</span>
                              
                            </span>
                          </span>
                        </span>
                      </h3>
                      <ul>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>300 TOTAL PROSPECTOR MINERS</span>
                        </li>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>110 GH INITIAL HASHRATE</span>
                        </li>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>PLAYABLE GAME CHARACTER</span>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>&nbsp;</span>
                        </li>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>WINDMILL REQUIRED TO POWER</span>
                        </li>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>MINER HASHRATE CAN BE UPGRADED</span>
                        </li>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>EARN ADDITIONAL REWARDS IN GAME, FREE</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="u-container-style u-image u-layout-cell u-radius-14 u-shading u-size-30 u-image-3" data-image-width="1493" data-image-height="1024">
                  <div className="u-border-8 u-border-grey-80 u-container-layout u-container-layout-2">
                    <img className="u-image u-image-circle u-image-contain u-preserve-proportions u-image-4" src="/assets/images/bitcoin.png" alt="" data-image-width="64" data-image-height="64"/>
                    <div className="fr-view u-align-center u-clearfix u-rich-text u-text u-text-2">
                      <h3>
                        <span style={{ fontSize: '1.5rem' }}>
                          <span className="u-text-palette-4-light-1">
                            <span className="u-custom-font" style={{ fontFamily: 'Koulen' }}>
                              <span style={{ fontSize: '1.875rem' }}>
                                <span style={{ fontSize: '1.5rem' }}>&nbsp;BITCOIN PROSPECTOR MINING</span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </h3>
                      <ul>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>2000 TOTAL PROSPECTOR MINERS</span>
                        </li>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>5 TH INITIAL HASHRATE</span>
                        </li>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>PLAYABLE GAME CHARACTER</span>
                        </li>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>WINDMILL REQUIRED TO POWER</span>
                        </li>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>MINER HASHRATE CAN BE UPGRADED</span>
                        </li>
                        <li>
                          <span className="u-custom-font u-font-open-sans" style={{ fontWeight: 700 }}>EARN ADDITIONAL REWARDS IN GAME, FREE</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="u-align-center u-border-2 u-border-no-left u-border-no-right u-border-palette-4-base u-clearfix u-grey-90 u-section-3" id="sec-33d5">
        <div className="u-clearfix u-sheet u-sheet-1">
          <h3 className="u-align-center u-custom-font u-text u-text-palette-3-light-1 u-text-1">FIRST STEP</h3>
          <div className="u-clearfix u-gutter-4 u-layout-wrap u-layout-wrap-1">
            <div className="u-layout">
              <div className="u-layout-row">
              <div className="u-container-style u-image u-image-round u-layout-cell u-radius-14 u-shading u-size-30 u-image-1" data-image-width="616" data-image-height="616" style={{ margin: '0 auto', textAlign: 'center' }}>
                <div className="u-border-2 u-border-palette-4-base u-container-layout u-container-layout-1">
                  <h4 className="u-align-center u-custom-font u-text u-text-default u-text-palette-4-light-1 u-text-2">MINT A WINDMILL</h4>
                  <h4 className="u-align-center u-custom-font u-text u-text-3">select cap</h4>
                  <p className="u-align-center u-custom-font u-text u-text-palette-4-dark-1 u-text-3">
                    <select
                      value={this.state.selectedCap}
                      onChange={this.handleCapChange}
                      name="selectedCap"
                      style={{ margin: '0 auto', display: 'block' }}
                    >
                      {this.state.availableCaps.map((capValue) => (
                        <option key={capValue} value={capValue}>
                          {capValue}
                        </option>
                      ))}
                    </select> Watts
                  </p>
                  <a onClick={this.mintWindmill} className="u-align-center u-border-none u-btn u-btn-round u-button-style u-hover-palette-1-light-3 u-palette-3-base u-radius-6 u-btn-1">MINT</a>
                  <h3 className="u-align-center u-custom-font u-text u-text-5">
                  <span style={{ fontSize: '1rem' }}>50w @ 40 PROSPECT <br/>100w @ 60 PROSPECT<br/>150w @ 90 PROSPECT</span>
                  </h3>
                </div>
              </div>
                <div className="u-container-style u-image u-image-round u-layout-cell u-radius-14 u-shading u-size-30 u-image-2" data-image-width="1536" data-image-height="1536">
                  <div className="u-border-2 u-border-palette-4-base u-container-layout u-container-layout-2">
                    <h3 className="u-align-center u-custom-font u-text u-text-default u-text-palette-4-light-1 u-text-5">MINER SPECIFICATIONS</h3>
                    <br/>
                    <img className="u-image u-image-circle u-image-contain u-preserve-proportions u-image-3" src="/assets/images/kaspa.png" alt="" data-image-width="200" data-image-height="200"/>
                    <img className="u-image u-image-contain u-image-round u-preserve-proportions u-radius-10 u-image-4" src="/assets/images/5968260.png" alt="" data-image-width="128" data-image-height="128"/>
                    <h4 className="u-align-center u-custom-font u-text u-text-default u-text-6">0.37 W PER/GH<br/>$0.26/YR PER GH<br/>100 GH = 37 W 
                    </h4>
                    <h3 className="u-align-center u-custom-font u-text u-text-7">21.35 W PER TH<br/>$14.96/YR/TH<br/>5TH = 107 W 
                    </h3> 
                    <p className="u-align-center u-small-text u-text u-text-variant u-text-10">Skale and Alt miners use gamified voxelwatts and voxelhash.<br/>Each miner requires 10W of Windmill power&nbsp;
              </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3 className="u-align-center u-custom-font u-text u-text-palette-3-light-1 u-text-8">SECOND STEP</h3>
          <div className="u-border-2 u-border-palette-1-light-1 u-container-style u-group u-image u-image-round u-radius-14 u-shading u-image-5" data-image-width="1080" data-image-height="1080">
            <div className="u-container-layout u-container-layout-3">
              
              <h3 className="u-align-center u-custom-font u-text u-text-default u-text-9">bitcoin 5TH</h3>
              <p className="u-align-center u-custom-font u-small-text u-text u-text-variant u-text-10">BOOST RATE<br/>{this.state.regMinerCost !== null
              ? parseFloat(Web3.utils.fromWei(this.state.btcMinerBoostRate.toString(), 'ether')).toFixed(2)
              : 'Loading...'} PROS P/TH&nbsp;
              </p>
              <a onClick={this.mintBTCMiner} className="u-border-none u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-3-base u-radius-6 u-btn-2">MINT</a>
              <p className="u-align-center u-text u-text-11">{this.state.seMinerCost !== null
              ? parseFloat(Web3.utils.fromWei(this.state.seMinerCost.toString(), 'ether')).toFixed(2)
              : 'Loading...'} PROS</p>
              <span className="u-file-icon u-icon u-icon-1"><img src="/assets/images/1146504.png" alt=""/></span>
              <p className="u-align-center u-small-text u-text u-text-variant u-text-12">
              {this.state.bitcoinStats.minersHashing !== null
                            ? this.state.bitcoinStats.minersHashing.toString()
                            : 'Loading...'}/2000</p>
              
              <div className="u-clearfix u-gutter-6 u-layout-wrap u-layout-wrap-2">
                <div className="u-layout">
                  <div className="u-layout-col">
                    <div className="u-size-30">
                      <div className="u-layout-row">
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-3">
                          <div className="u-border-2 u-border-palette-1-light-1 u-container-layout u-valign-bottom u-container-layout-4">
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-13">total<br/>BITCOIN PAID
                            </h4>
                            <h3 className="u-align-center u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-51">
                            {this.state.bitcoinStats.totalRewardsPaid !== undefined && this.state.bitcoinStats.totalRewardsPaid !== null
                              ? (parseFloat(Web3.utils.fromWei(this.state.bitcoinStats.totalRewardsPaid.toString(), 'ether'))* 1e10).toFixed(8)
                              : 'Start..'}
                            </h3>
                          </div>
                        </div>
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-4">
                          <div className="u-border-2 u-border-palette-1-base u-container-layout u-valign-bottom u-container-layout-5">
                            <h4 className="u-align-left u-custom-font u-text u-text-default u-text-15">miners</h4>
                            <h3 className="u-align-right u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-16">
                            {this.state.bitcoinStats.minersHashing !== null
                            ? this.state.bitcoinStats.minersHashing.toString()
                            : 'Loading...'}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="u-size-30">
                      <div className="u-layout-row">
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-5">
                          <div className="u-border-2 u-border-palette-1-light-1 u-container-layout u-valign-bottom u-container-layout-6">
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-17">hashrate (th)</h4>
                            <h3 className="u-align-right u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-18">
                            {this.state.bitcoinStats.totalHashrate !== null
                            ? this.state.bitcoinStats.totalHashrate.toString() // Convert the uint256 to a string
                            : 'Loading...'}
                            </h3>
                          </div>
                        </div>
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-6">
                          <div className="u-border-2 u-border-palette-1-base u-container-layout u-container-layout-7">
                            <img className="u-border-2 u-border-white u-image u-image-circle u-image-contain u-preserve-proportions u-image-6" src="/assets/images/5968260.png" alt="" data-image-width="128" data-image-height="128"/>
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-19">power<br/>(watts)
                            </h4>
                            <h3 className="u-align-center u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-20">
                            {this.state.bitcoinStats.totalPowerConsumption !== null
                            ? this.state.bitcoinStats.totalPowerConsumption.toString() // Convert the uint256 to a string
                            : 'Loading...'}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="u-border-2 u-border-palette-2-base u-container-style u-group u-image u-image-round u-radius-14 u-shading u-image-7" data-image-width="1080" data-image-height="1080">
            <div className="u-container-layout u-container-layout-8">
              <div className="u-clearfix u-gutter-6 u-layout-wrap u-layout-wrap-3">
                <div className="u-layout">
                  <div className="u-layout-col">
                    <div className="u-size-30">
                      <div className="u-layout-row">
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-7">
                          <div className="u-border-2 u-border-palette-2-base u-container-layout u-valign-bottom u-container-layout-9">
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-21">TOTAL<br/>KASPA PAID
                            </h4>
                            <p className="u-align-center u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-51">
                            {this.state.kaspaStats.totalRewardsPaid !== undefined && this.state.kaspaStats.totalRewardsPaid !== null
                              ? (parseFloat(Web3.utils.fromWei(this.state.kaspaStats.totalRewardsPaid.toString(), 'ether'))* 1e10).toFixed(4)
                              : 'Start..'}

                          </p>
                          </div>
                        </div>
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-8">
                          <div className="u-border-2 u-border-palette-2-light-1 u-container-layout u-valign-bottom u-container-layout-10">
                            <h4 className="u-align-left u-custom-font u-text u-text-default u-text-23">miners</h4>
                            <p className="u-align-right u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-24">
                            {this.state.kaspaStats.minersHashing !== null
                            ? this.state.kaspaStats.minersHashing.toString()
                            : 'Loading...'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="u-size-30">
                      <div className="u-layout-row">
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-9">
                          <div className="u-border-2 u-border-palette-2-base u-container-layout u-valign-bottom u-container-layout-11">
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-25">hashrate (gh)</h4>
                            <h3 className="u-align-right u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-51">
                              {this.state.kaspaStats.totalHashrate !== null
                            ? this.state.kaspaStats.totalHashrate.toString()
                            : 'Loading...'}
                            </h3>
                          </div>
                        </div>
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-10">
                          <div className="u-border-2 u-border-palette-2-light-1 u-container-layout u-container-layout-12">
                            <img className="u-border-2 u-border-white u-image u-image-circle u-image-contain u-preserve-proportions u-image-8" src="/assets/images/kaspa.png" alt="" data-image-width="128" data-image-height="128"/>
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-27">power<br/>(watts)
                            </h4>
                            <h3 className="u-align-center u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-28">{this.state.kaspaStats.totalPowerConsumption !== null
                            ? this.state.kaspaStats.totalPowerConsumption.toString() // Convert the uint256 to a string
                            : 'Loading...'}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="u-align-center u-custom-font u-text u-text-default u-text-29">kaspa 110 GH</h3>
              <p className="u-align-center u-custom-font u-small-text u-text u-text-variant u-text-10">BOOST RATE<br/>{this.state.regMinerCost !== null
              ? parseFloat(Web3.utils.fromWei(this.state.kasMinerBoostRate.toString(), 'ether')).toFixed(2)
              : 'Loading...'} PROS 10/GH&nbsp;
              </p>
              <a onClick={this.mintKASMiner} className="u-border-none u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-3-base u-radius-6 u-btn-3">MINT</a>
              <p className="u-align-center u-text u-text-31">{this.state.kasMinerCost !== null
              ? parseFloat(Web3.utils.fromWei(this.state.kasMinerCost.toString(), 'ether')).toFixed(2)
              : 'Loading...'} PROS</p>
              <span className="u-file-icon u-icon u-icon-2"><img src="/assets/images/1146504.png" alt=""/></span>
              <p className="u-align-center u-small-text u-text u-text-variant u-text-32">
                {this.state.preSaleMiner !== null
                  ? `${this.state.preSaleMiner.toString()}/300`
                  : 'Loading...'}
              </p>
            </div>
          </div>
          <div className="u-border-2 u-border-palette-4-light-1 u-container-style u-group u-image u-image-round u-radius-14 u-shading u-image-9" data-image-width="1080" data-image-height="1080">
            <div className="u-container-layout u-container-layout-13">
              <h3 className="u-align-center u-custom-font u-text u-text-default u-text-34">SKALE</h3>
              <p className="u-align-center u-custom-font u-small-text u-text u-text-variant u-text-10">BOOST RATE {this.state.regMinerCost !== null
              ? parseFloat(Web3.utils.fromWei(this.state.sklMinerBoostRate.toString(), 'ether')).toFixed(2)
              : 'Loading...'} PROS P/VH&nbsp;
              </p>
              <a onClick={this.mintSKLMiner} className="u-border-none u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-3-base u-radius-6 u-btn-4">MINT</a>
              <p className="u-align-center u-text u-text-36">{this.state.regMinerCost !== null
              ? parseFloat(Web3.utils.fromWei(this.state.regMinerCost.toString(), 'ether')).toFixed(2)
              : 'Loading...'} PROS</p>
              <span className="u-file-icon u-icon u-icon-3"><img src="/assets/images/1146504.png" alt=""/></span>
              <p className="u-align-center u-small-text u-text u-text-variant u-text-37">
              {this.state.skaleStats.minersHashing !== null
                            ? this.state.skaleStats.minersHashing.toString()
                            : 'Loading...'}/1000</p>
              <div className="u-clearfix u-gutter-6 u-layout-wrap u-layout-wrap-4">
                <div className="u-layout">
                  <div className="u-layout-col">
                    <div className="u-size-30">
                      <div className="u-layout-row">
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-11">
                          <div className="u-border-2 u-border-palette-4-light-1 u-container-layout u-container-layout-14">
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-38">TOTAL<br/>SKALE PAID
                            </h4>
                            <h3 className="u-align-center u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-51">
                            {this.state.skaleStats.totalRewardsPaid !== undefined && this.state.skaleStats.totalRewardsPaid !== null
                              ? parseFloat(Web3.utils.fromWei(this.state.skaleStats.totalRewardsPaid.toString(), 'ether')).toFixed(4)
                              : 'Loading...'}
                            </h3>
                          </div>
                        </div>
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-12">
                          <div className="u-border-2 u-border-palette-4-base u-container-layout u-valign-bottom u-container-layout-15">
                            <h4 className="u-align-left u-custom-font u-text u-text-default u-text-40">miners</h4>
                            <h3 className="u-align-right u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-41">
                            {this.state.skaleStats.minersHashing !== null
                            ? this.state.skaleStats.minersHashing.toString()
                            : 'Loading...'}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="u-size-30">
                      <div className="u-layout-row">
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-13">
                          <div className="u-border-2 u-border-palette-4-light-1 u-container-layout u-container-layout-16">
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-42">hashrate (vh)</h4>
                            <h3 className="u-align-right u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-43">
                            {this.state.skaleStats.totalHashrate !== null
                            ? this.state.skaleStats.totalHashrate.toString()
                            : 'Loading...'}
                            </h3>
                          </div>
                        </div>
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-14">
                          <div className="u-border-2 u-border-palette-4-base u-container-layout u-container-layout-17">
                            <img className="u-border-2 u-border-white u-image u-image-circle u-image-contain u-preserve-proportions u-image-10" src="/assets/images/skl-square.jpg" alt="" data-image-width="225" data-image-height="225"/>
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-44">block<br/>reward
                            </h4>
                            <h3 className="u-align-center u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-45">
                            {this.state.skaleStats.blockReward !== undefined && this.state.skaleStats.blockReward !== null
                              ? parseFloat(Web3.utils.fromWei(this.state.skaleStats.blockReward.toString(), 'ether')).toFixed(4)
                              : 'Loading...'}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="u-border-2 u-border-custom-color-13 u-container-style u-group u-image u-image-round u-radius-14 u-shading u-image-11" data-image-width="800" data-image-height="1000">
            <div className="u-container-layout u-container-layout-18">
              <h3 className="u-align-center u-custom-font u-text u-text-default u-text-46">ALTCOIN</h3>
              <p className="u-align-center u-custom-font u-small-text u-text u-text-variant u-text-10">BOOST RATE {this.state.regMinerCost !== null
              ? parseFloat(Web3.utils.fromWei(this.state.altMinerBoostRate.toString(), 'ether')).toFixed(2)
              : 'Loading...'} PROS P/VH&nbsp;
              </p>
              <a onClick={this.mintALTMiner} className="u-border-none u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-3-base u-radius-6 u-btn-5">MINT</a>
              <p className="u-align-center u-text u-text-48">{this.state.altMinerCost !== null
  ? parseFloat(Web3.utils.fromWei(this.state.altMinerCost.toString(), 'ether')).toFixed(2)
  : 'Loading...'} PROS</p><span className="u-file-icon u-icon u-icon-4"><img src="/assets/images/1146504.png" alt=""/></span>
              <br/>
              <p className="u-align-center u-small-text u-text u-text-variant u-text-49">
              {this.state.altStats.minersHashing !== null
                            ? this.state.altStats.minersHashing.toString()
                            : 'Loading...'}/9999</p>
             
              <div className="u-clearfix u-gutter-6 u-layout-wrap u-layout-wrap-5">
                <div className="u-layout">
                  <div className="u-layout-col">
                    <div className="u-size-30">
                      <div className="u-layout-row">
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-15">
                          <div className="u-border-2 u-border-custom-color-13 u-container-layout u-valign-bottom u-container-layout-19">
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-50">CURRENTLY<br/>MINING
                            </h4>
                            <h3 className="u-align-center u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-51">
                            RUBY
                            </h3>
                          </div>
                        </div>
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-16">
                          <div className="u-border-2 u-border-custom-color-11 u-container-layout u-valign-bottom u-container-layout-20">
                            <h4 className="u-align-left u-custom-font u-text u-text-default u-text-52">miners</h4>
                            <h3 className="u-align-right u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-53">
                            {this.state.altStats.minersHashing !== null
                            ? this.state.altStats.minersHashing.toString()
                            : 'Loading...'}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="u-size-30">
                      <div className="u-layout-row">
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-17">
                          <div className="u-border-2 u-border-custom-color-13 u-container-layout u-valign-bottom u-container-layout-21">
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-54">hashrate (VH)</h4>
                            <h3 className="u-align-right u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-55">
                            {this.state.altStats.totalHashrate !== null
                            ? this.state.altStats.totalHashrate.toString()
                            : 'Loading...'}
                            </h3>
                          </div>
                        </div>
                        <div className="u-container-style u-custom-color-3 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-18">
                          <div className="u-border-2 u-border-custom-color-11 u-container-layout u-container-layout-22">
                            <img className="u-border-2 u-border-white u-image u-image-circle u-image-contain u-preserve-proportions u-image-12" src="/assets/images/prospectorsnft.jpg" alt="" data-image-width="200" data-image-height="200"/>
                            <h4 className="u-align-center u-custom-font u-text u-text-default u-text-56">block<br/>Reward
                            </h4>
                            <h3 className="u-align-center u-custom-font u-font-open-sans u-text u-text-default u-text-palette-3-base u-text-57">
                            {this.state.altStats.blockReward !== undefined && this.state.altStats.blockReward !== null
                              ? parseFloat(Web3.utils.fromWei(this.state.altStats.blockReward.toString(), 'ether')).toFixed(4)
                              : 'Loading...'}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="u-align-center u-black u-clearfix u-section-4" id="sec-9831">
        <div className="u-clearfix u-sheet u-sheet-1">
          <h1 className="u-align-center u-custom-font u-text u-text-palette-5-light-1 u-text-1" data-animation-name="" data-animation-duration="0" data-animation-direction="">YOUR DASHBOARD</h1>
          
          <div className="u-border-1 u-border-no-left u-border-no-right u-border-white u-container-style u-group u-shape-rectangle u-group-1">
          {this.state.generators.map((generator, index) => (
          <div key={`generator-${index}`} className="u-container-layout u-container-layout-1">
              <h3 className="u-align-center u-custom-font u-text u-text-default u-text-2">Your Power</h3>
              <img className="u-image u-image-round u-preserve-proportions u-radius-14 u-image-1" src="/assets/images/windmill.png" alt="" data-image-width="616" data-image-height="616"/>
              <h3 className="u-align-center u-custom-font u-text u-text-default u-text-3">TokenId: <span className="u-text-palette-3-base"> {generator.tokenId} </span>
              </h3>
              <h3 className="u-align-center u-custom-font u-text u-text-default u-text-4">Current Usage: <span className="u-text-palette-3-base">{generator.currentPowerUsed}</span> watts
              </h3>
              <h3 className="u-align-center u-custom-font u-text u-text-default u-text-5">Capacity: <span className="u-text-palette-3-base">{generator.generatorCap}</span> watts
              </h3>
              <h3 className="u-align-center u-custom-font u-text u-text-default u-text-6">BOOST RATE: <span className="u-text-palette-3-base">{parseFloat(Web3.utils.fromWei(this.state.generatorsBoost.toString(), 'ether')).toFixed(2)}</span> $prospect
              </h3>
              <a onClick={() => this.boostGeneratorWatts(generator.tokenId)} className="u-border-2 u-border-hover-grey-5 u-border-white u-btn u-btn-round u-button-style u-custom-color-18 u-custom-font u-hover-palette-1-light-2 u-radius-6 u-btn-1">increase capacity</a>
              <p className="u-align-left u-custom-font u-text u-text-palette-2-dark-1 u-text-2"><input
                  type="number"
                  min="1"
                  max="100"
                  value={this.state.selectedWatts}
                  onChange={this.handleWattChange}
                />
                </p>
            </div>
          ))}
          </div>
          
          <div className="u-clearfix u-gutter-8 u-layout-wrap u-layout-wrap-1">
                    {this.state.miners.map((miner) => (
            <div key={`miner-${miner.tokenId}`} className="u-layout">
              <div className="u-layout-col">
                <div className="u-size-20">
                  <div className="u-layout-row">
                    <div className="u-container-style u-grey-90 u-layout-cell u-radius-14 u-shape-round u-size-20 u-layout-cell-1">
                      <div className="u-border-1 u-border-grey-90 u-container-layout u-container-layout-2">
                        <h3 className="u-align-center u-custom-font u-text u-text-default u-text-8">
                          <span style={{ fontSize: '1.125rem' }}>mining</span>
                          <br/>
                          <span className="u-text-palette-3-base"> {miner.token} </span>
                        </h3>
                      </div>
                    </div>
                    <div className="u-container-style u-grey-90 u-layout-cell u-radius-14 u-shape-round u-size-40 u-layout-cell-2">
                      <div className="u-border-2 u-border-grey-90 u-container-layout u-container-layout-3">
                      
                        <h3 className="u-align-center u-custom-font u-text u-text-default u-text-9">
                          <span style={{ fontSize: '1.125rem' }}>claimable rewards</span>
                          <br/>
                          

                          <span className="u-text-palette-3-base">
                          <span className="u-text-palette-3-base"> 
                          {this.state.pendingRewards[miner.tokenId] !== undefined
                              ? (
                                  ((miner.tokenId >= 1 && miner.tokenId < 2000) || (miner.tokenId >= 3000 && miner.tokenId < 6200))
                                  ? (parseFloat(
                                      Web3.utils.fromWei(
                                        this.state.pendingRewards[miner.tokenId].toString(),
                                        'ether'
                                      )
                                    ) * 1e10).toFixed(8)
                                  : parseFloat(
                                      Web3.utils.fromWei(
                                        this.state.pendingRewards[miner.tokenId].toString(),
                                        'ether'
                                      )
                                    ).toFixed(8)
                                )
                              : 'Loading...'}
                            </span>

                            </span>
                        
                         
                          <br/>
                          <span style={{ fontSize: '1.125rem' }}>{miner.token}</span>
                        </h3>
                        
                        
                        <a onClick={() => this.claimRewards(miner.tokenId)} className="u-align-center u-border-2 u-border-hover-white u-border-white u-btn u-btn-round u-button-style u-custom-color-13 u-custom-font u-hover-custom-color-11 u-radius-6 u-text-body-alt-color u-text-hover-white u-btn-2">CLAIM REWARDS</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="u-size-40">
                  <div className="u-layout-row">
                    <div className="u-size-40 u-size-60-md">
                      <div className="u-layout-col">
                        <div className="u-size-30">
                          <div className="u-layout-row">
                            <div className="u-container-style u-grey-90 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-3">
                              <div className="u-border-2 u-border-grey-90 u-container-layout u-valign-left u-container-layout-8">
                                <br/>
                                <br/>
                                <h3 className="u-align-center u-custom-font u-text u-text-default u-text-8">
                                  
                                  <span style={{ fontSize: '1.125rem' }}>Estimated Daily Earnings</span>
                                  <br/>
                                  <span className="u-text-palette-3-base"> {parseFloat(Web3.utils.fromWei(miner.dailyEstimate.toString(), 'ether')).toFixed(8)} </span>
                                  <br/>
                                </h3>
                              </div>
                            </div>
                            <div className="u-container-style u-grey-90 u-layout-cell u-radius-14 u-shape-round u-size-30 u-layout-cell-4">
                              <div className="u-border-2 u-border-grey-90 u-container-layout u-valign-left u-container-layout-5">
                                <br/>
                                <h3 className="u-align-center u-custom-font u-text u-text-8">
                                  <span style={{ fontSize: '1.125rem' }}>power consumption:</span>
                                  <br/>
                                  
                                 <span className="u-text-palette-3-base"> {miner.powerConsumption} W</span>
                                 
            
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="u-size-30">
                          <div className="u-layout-row">
                            <div className="u-container-style u-grey-90 u-layout-cell u-radius-14 u-shape-round u-size-60 u-layout-cell-5">
                              <div className="u-border-2 u-border-grey-90 u-container-layout u-container-layout-6">
                                <h3 className="u-align-center u-custom-font u-text u-text-default u-text-12">
                                  <span style={{ fontSize: '1.125rem' }}>hashrate</span>
                                  <br/>
                                  <span className="u-text-palette-3-base">{miner.hashrate}</span>
                                  <br/>
                                  <span style={{ fontSize: '1.35rem' }}>{miner.hashMeasured}</span>
                                </h3>
                                <a onClick={() => this.boostMinerHashrate(miner.tokenId)} className="u-align-center u-border-2 u-border-hover-white u-border-white u-btn u-btn-round u-button-style u-custom-font u-hover-palette-1-light-2 u-palette-3-base u-radius-6 u-text-body-color u-text-hover-black u-btn-3">add HASHpower<br/>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="u-size-20 u-size-60-md">
                      <div className="u-layout-col">
                        <div className="u-container-style u-layout-cell u-radius-14 u-shape-round u-size-60 u-layout-cell-6">
                          <div className="u-border-1 u-border-white u-container-layout u-container-layout-7">
                            <h3 className="u-align-center u-custom-font u-text u-text-default u-text-13">
                              <span style={{ fontSize: '1.125rem' }}>Miner tokenid: {miner.tokenId}</span>
                            </h3>

                            <img className="u-align-center u-flip-horizontal u-image u-image-round u-preserve-proportions u-radius-10 u-image-2" src={`http://pickaxecrypto.io/img/johnny/${miner.tokenId}.png`} alt="" data-image-width="1080" data-image-height="1080"/>
                            <h3 className="u-align-center u-custom-font u-text u-text-default u-text-15">miner name: <span className="u-text-palette-3-base"> {miner.name} </span>
                            </h3>
                                    <div className="u-align-center u-flex u-flex-center">
                              <a
                                onClick={() => this.updateMinerName(miner.tokenId)}
                                className="u-align-center u-border-2 u-border-hover-white u-border-white u-btn u-btn-round u-button-style u-custom-font u-hover-palette-3-base u-palette-3-base u-radius-6 u-btn-4"
                              >
                                SET MINER NAME
                              </a>
                              <br />
                              <input
                                className="u-align-center u-custom-font u-text u-text-custom-color-14 u-text-16"
                                type="text"
                                value={this.state.newName}
                                onChange={(e) => this.setState({ newName: e.target.value })}
                                placeholder="Enter Name"
                                style={{ marginBottom: '20px' }} // Add margin to the bottom
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="u-align-center u-border-3 u-border-white u-expanded-width u-line u-line-horizontal u-line-1"></div>
            </div>
            
          ))}
          </div>
          
          
        </div>
      </section>
      
      
      <footer className="u-align-center u-black u-clearfix u-footer u-footer" id="sec-dbc8"><div className="u-clearfix u-sheet u-sheet-1">
          <div className="u-expanded-width u-list u-list-1">
            <div className="u-repeater u-repeater-1">
              <div className="u-border-2 u-border-white u-container-style u-list-item u-radius-12 u-repeater-item u-shape-round">
                <div className="u-container-layout u-similar-container u-container-layout-1">
                  <img className="u-align-center u-image u-image-contain u-image-1" src="/assets/images/Nebula_Logo.png" data-image-width="500" data-image-height="500" data-href="https://nebulachain.io" data-target="_blank"/>
                </div>
              </div>
              <div className="u-border-2 u-border-white u-container-style u-list-item u-radius-12 u-repeater-item u-shape-round">
                <div className="u-container-layout u-similar-container u-container-layout-2">
                  <img className="u-align-center u-image u-image-contain u-image-2" src="/assets/images/skale_logo_white_transparent.svg" data-image-width="250" data-image-height="150" data-href="https://skale.space" data-target="_blank"/>
                </div>
              </div>
            </div>
          </div>
          <h4 className="u-custom-font u-text u-text-default u-text-palette-4-base u-text-1">verse</h4>
          <h6 className="u-custom-font u-text u-text-default u-text-palette-3-base u-text-2">prospectorsnft</h6>
          <h4 className="u-custom-font u-text u-text-default u-text-palette-1-base u-text-3">voxel</h4>
          <h6 className="u-align-center u-custom-font u-text u-text-default u-text-4">
            <a href="https://pickaxecrypto.io" className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1" target="_blank"> Copyright © 2023 pickaxe crypto</a>
          </h6><span className="u-file-icon u-icon u-text-palette-1-base u-icon-1" data-href="https://discord.gg/jx5qSyjV9e" data-target="_blank"><img src="/assets/images/3670325-bb91a2e5.png" alt=""/></span>
        </div></footer> 
  </body>
      );
  }
}
  export default Home;